html,
body,
#root,
.video,
.video video
{
  width: 100%;
  height: 100%;
}
.video.video-js {
  position: relative;
}
.video video {
  display: inline-block !important;
}

.video-js .vjs-big-play-button,.vjs-big-play-button::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.video-js .vjs-big-play-button {
  height: 70px;
  width: 70px;
  border-radius: 50%;
  border: 2px solid #ddd;
  font-size: 0;
}
.video-js .vjs-big-play-button::before {
  content: '';
  display: block;
  border-left: 25px solid #ddd;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: none;
  left: 57%;
}

.vjs-loading-spinner::after {
  display: none;
}
.vjs-loading-spinner {
  font-size: 0;
}

.vjs_video_3-dimensions {
  width: 100% !important;
  height: 100% !important;
}